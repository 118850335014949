import WistiaVideoPlayer from '@/components/shared/WistiaVideoPlayer';
import {AlignmentEnum, IconEnum} from '@/enums';
import {useMediaQuery} from '@/hooks/useMediaQuery';
import InterfaceButton from '@/components/base/elements/InterfaceButton/InterfaceButton';
import Image from '@/components/base/elements/Image/Image';

import type {NewsHeroVideo, NewsHeroVideoFallBackImg} from '../types';

import VideoMp4 from './VideoMp4';
import YoutubeVideo from './YoutubeVideo';

interface HeroVideoProps {
  heroVideo: NewsHeroVideo;
  handleClick: () => void;
}

interface VideoComponentProps {
  value: string;
  type?: string;
  image: NewsHeroVideoFallBackImg;
  controls?: boolean;
  className?: string;
  unmute?: boolean;
  isVisible?: boolean;
}

export const VideoComponent = ({
  value,
  type,
  image,
  className = '',
  controls = false,
  unmute = false,
  isVisible = true,
}: VideoComponentProps) => {
  const youtubeRegExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const matchYoutube = value.match(youtubeRegExp);

  if (!isVisible) {
    return <Image {...image} alt={image.altText} />;
  }

  if (matchYoutube && matchYoutube[7].length === 11) {
    return (
      <YoutubeVideo
        videoId={matchYoutube[7]}
        image={image}
        className={className}
        controls={controls}
        unmute={unmute}
      />
    );
  } else if (value.includes('.mp4')) {
    return (
      <VideoMp4
        mp4Source={value}
        fallbackImage={image.src}
        fallbackImageAlt={image.altText}
        className={className}
        controls={controls}
        unmute={unmute}
      />
    );
  } else if (type !== 'url' || !value.includes('http')) {
    return (
      <WistiaVideoPlayer
        videoId={value}
        autoPlay
        disableBottomBar
        image={{alt: image.altText}}
        buttonPosition={AlignmentEnum.Right}
        aspectRatio="16/9"
        disableContextMenu={true}
        classNames={{
          playButton: 'hidden',
          wrapper: className,
        }}
      />
    );
  } else {
    return null;
  }
};

export default function HeroVideo({heroVideo, handleClick}: HeroVideoProps) {
  const {
    image,
    metafield: {type, value},
  } = heroVideo;
  const isMobile = useMediaQuery('(max-width: 900px)');
  return (
    <div className="relative">
      <VideoComponent value={value} type={type} image={image} />
      <InterfaceButton
        className="absolute z-10 shadow-play-button bottom-2.5 left-2.5 md:bottom-8 md:left-8 md:w-20 md:h-20"
        size={isMobile ? 'small' : 'large'}
        onClick={handleClick}
        iconProps={{
          icon: IconEnum.PlayPause,
        }}
      />
    </div>
  );
}
