import cn from 'classnames';
import {useState} from 'react';

import Image from '@/components/base/elements/Image/Image';

import {type NewsHeroVideoFallBackImg} from '../types';

interface YoutubeVideo {
  videoId: string;
  image: NewsHeroVideoFallBackImg;
  className?: string;
  controls?: boolean;
  unmute?: boolean;
}

export default function YoutubeVideo({
  videoId,
  image,
  className = '',
  controls = false,
  unmute = false,
}: YoutubeVideo) {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  return (
    <div>
      {!isLoaded && image?.src && (
        <Image {...image} alt={image.altText} className="w-full aspect-video" />
      )}
      <iframe
        className={cn(
          'w-full aspect-video',
          {
            'h-0': !isLoaded,
            'pointer-events-none': !controls,
          },
          className,
        )}
        src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=${Number(
          !unmute,
        )}&controls=${Number(
          controls,
        )}&showinfo=0&loop=1&rel=0&playlist=${videoId}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        onLoad={() =>
          setTimeout(() => {
            setIsLoaded(true);
          }, 500)
        }
      ></iframe>
    </div>
  );
}
