import {useLocation, useNavigate} from '@remix-run/react';
import {cva} from 'class-variance-authority';
import React, {useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';

import {useTranslations} from '@/hooks/useTranslations';
import {useKeyPress} from '@/hooks/useKeyPress';
import {useFocusTrap} from '@/hooks/useFocusTrap';
import {twMerge} from '@/stylesheets/twMerge';

interface ModalProps {
  ariaLabel?: string;
  children: React.ReactNode;
  id: string;
}

const containerStyles = cva('grid place-items-center fixed z-50 inset-0', {
  variants: {
    isVisible: {
      true: '',
      false: 'pointer-events-none opacity-0',
    },
    transitionsAvailable: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [
    {
      transitionsAvailable: false,
      className: 'transition-opacity duration-500',
    },
    {
      isVisible: false,
      transitionsAvailable: false,
      className: 'transition-opacity duration-500',
    },
  ],
});

const screenStyles = cva('fixed inset-0 bg-black', {
  variants: {
    isVisible: {
      true: 'opacity-80',
      false: 'opacity-0 delay-300',
    },
    transitionsAvailable: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [
    {
      transitionsAvailable: false,
      className: 'transition-opacity duration-500',
    },
  ],
});

const wrapperStyles = cva(
  'grid place-items-center container !w-full h-full px-4 duration-[450ms]',
  {
    variants: {
      isVisible: {
        true: 'opacity-100',
        false: 'opacity-0 delay-100',
      },
    },
  },
);

const contentStyles = cva(
  'main-video w-full max-w-[90dvh] max-h-[90dvw] rounded-3xl transition-all transform-gpu duration-[550ms] ease-out',
  {
    variants: {
      isVisible: {
        true: 'scale-100',
        false: 'scale-90',
      },
    },
  },
);

export default function Modal({ariaLabel, children, id}: ModalProps) {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef<HTMLDivElement>();
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useTranslations();
  const [transitionsAvailable, setTransitionsAvailable] = useState(true);

  useEffect(() => {
    const el = document.createElement('div');
    elementRef.current = el;
    const target = elementRef.current;

    document.body.appendChild(target);

    return () => {
      document.body.removeChild(target);
    };
  }, []);

  useEffect(() => {
    if (location.hash.substring(1) === `/modal/${id}`) {
      navigate(location.pathname + location.search);
      setIsVisible(true);
    }
  }, [
    navigate,
    location.pathname,
    location.search,
    location.hash,
    id,
    setIsVisible,
  ]);

  const transitionMotion = async (className: string, callback: () => void) => {
    const experimentalDocument: any = document;
    if (!experimentalDocument.startViewTransition) {
      setTransitionsAvailable(false);
      callback();
      return;
    }

    const classes = className
      .split(' ')
      .map((cn) => cn.trim())
      .filter(Boolean);

    document.documentElement.classList.add(...classes);

    try {
      const transition = experimentalDocument.startViewTransition(callback);
      await transition.finished;
    } finally {
      document.documentElement.classList.remove(...classes);
    }
  };

  const closeModal = () => {
    transitionMotion('move-video slow', () => {
      setIsVisible(false);
    });
  };

  useKeyPress('Escape', closeModal);
  useFocusTrap(elementRef, isVisible);

  if (elementRef.current)
    return (
      <>
        {createPortal(
          <div
            className={twMerge(
              containerStyles({isVisible, transitionsAvailable}),
            )}
            role="dialog"
            aria-modal
            aria-label={ariaLabel}
            aria-hidden={!isVisible}
            data-component-name={`modal-${id}`}
          >
            <div
              className={screenStyles({
                isVisible,
                transitionsAvailable,
              })}
            />
            <div className={wrapperStyles({isVisible})}>
              <div className={contentStyles({isVisible})}>
                <div
                  className="absolute right-0 md:right-[-50px] top-[-50px] md:top-0"
                  data-component-name={`pip-${id}`}
                >
                  <button
                    className="text-lg text-black hover:scale-105 active:scale-100 rounded-full bg-[#978DE7]"
                    onClick={closeModal}
                    aria-label={t('global:ariaLabels.modal.close')}
                    data-component-name="close-modal"
                  >
                    <svg width="35" height="35" viewBox="0 0 42 42" fill="none">
                      <path
                        d="M30.1875 11.8125L11.8125 30.1875"
                        stroke="black"
                        strokeWidth="2.625"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.8125 11.8125L30.1875 30.1875"
                        stroke="black"
                        strokeWidth="2.625"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                {React.Children.map(children, (child) => {
                  const props = {isVisible};
                  return React.isValidElement(child)
                    ? React.cloneElement(child, props)
                    : child;
                })}
              </div>
            </div>
          </div>,
          elementRef.current,
        )}
      </>
    );

  return null;
}
