import cn from 'classnames';
import {useEffect, useRef, useState} from 'react';

import useReducedMotion from '@/hooks/useReducedMotion';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import Image from '@/components/base/elements/Image/Image';

interface VideoBannerProps {
  mp4Source: string;
  fallbackImage: string;
  fallbackImageAlt?: string;
  loop?: boolean;
  className?: string;
  controls?: boolean;
  unmute?: boolean;
}

export default function VideoBanner({
  mp4Source,
  fallbackImage,
  fallbackImageAlt,
  loop,
  className = '',
  controls = false,
  unmute = false,
}: VideoBannerProps) {
  const videoRef = useRef<any>(null);
  const isReducedMotion = useReducedMotion(false);
  const [shouldPlay, setShouldPlay] = useState<boolean>(false);

  useIntersectionObserver(
    videoRef,
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (!shouldPlay && entry.isIntersecting && !isReducedMotion) {
          setShouldPlay(true);
        }
      });
    },
    {rootMargin: '500px'},
  );

  useEffect(() => {
    shouldPlay && videoRef.current?.load();
  }, [shouldPlay]);

  return isReducedMotion ? (
    <Image
      src={fallbackImage}
      alt={fallbackImageAlt}
      className={cn('aspect-video', className)}
      loading="lazy"
    />
  ) : (
    <video
      ref={videoRef}
      muted={!unmute}
      autoPlay
      playsInline
      disableRemotePlayback
      loop={loop}
      controls={controls}
      className={cn('aspect-video', className)}
    >
      <source src={shouldPlay ? mp4Source : ''} type="video/mp4" />
    </video>
  );
}
